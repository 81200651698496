import { Hidden } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import styles from './JulyTopBanner.module.scss';

export default function JulyTopBanner() {
  return (
    <section className={styles.section}>
      <div className={styles.img} />
      <p className={styles.text2}>
        <b>キャンペーン対象者</b> ：
        <Hidden smUp>
          <br />
        </Hidden>
        キャンペーンメールを受信された方、
        <Hidden xsDown>
          <br />
        </Hidden>
        もしくはペイパルのマイアカウントでバナーが表示された方
      </p>
      <p className={styles.text3}>
        <b>対象期間</b> ：
        <Hidden smUp>
          <br />
        </Hidden>
        2022年11月21日（月） 00:00〜 11月30日（水）23:59
      </p>
      <OutboundLink
        href="https://paidy.com/shop/"
        rel="noopener noreferrer"
        target="_blank"
        className={styles.btn}
      >
        使えるお店を探す
      </OutboundLink>
    </section>
  );
}
